import * as React from 'react';
import Layout from '../components/layout';
import { useStaticQuery, graphql } from 'gatsby';
import '../styles/about/layout.scss';
import '../styles/about/images.scss';
import { motion } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image';

const AboutPage = () => {
    const data = useStaticQuery(graphql`
        query AboutQuery {
            allWpPage(filter: { slug: { eq: "about" } }) {
                nodes {
                    about {
                        aboutImageOne {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(quality: 100, formats: [WEBP])
                                }
                            }
                        }
                        aboutImageTwo {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(quality: 100, formats: [WEBP])
                                }
                            }
                        }
                        aboutTextOne
                        aboutTextTwo
                        aboutTitleOne
                        aboutTitleTwo
                    }
                }
            }
        }
    `);

    const item = data.allWpPage.nodes[0].about;

    // Animations

    const containerVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.1,
                type: 'tween',
                when: 'beforeChildren',
                staggerChildren: 0.4
            }
        }
    };

    const fadeRightVariants = {
        hidden: {
            opacity: 0,
            x: '-50px'
        },
        visible: {
            opacity: 1,
            x: 0
        }
    };

    const fadeLeftVariants = {
        hidden: {
            opacity: 0,
            x: '50px'
        },
        visible: {
            opacity: 1,
            x: 0
        }
    };

    const fadeUpVariants = {
        hidden: {
            opacity: 0,
            y: '50px'
        },
        visible: {
            opacity: 1,
            y: 0
        }
    };

    return (
        <Layout>
            <motion.main className="site-wrap" variants={containerVariants} initial="hidden" animate="visible">
                <title>About</title>
                <section className="about__top-section">
                    <motion.figure
                        className="about__image-one"
                        variants={fadeUpVariants}
                        transition={{
                            duration: 1,
                            type: 'tween'
                        }}
                    >
                        {item.aboutImageOne && (
                            <GatsbyImage
                                image={item.aboutImageOne.localFile.childImageSharp.gatsbyImageData}
                                alt="business owner"
                            />
                        )}
                    </motion.figure>
                    <motion.div
                        className="about__text-box"
                        variants={fadeLeftVariants}
                        transition={{
                            duration: 1,
                            type: 'tween',
                            delay: 0.6
                        }}
                    >
                        <h2 className="page-headers font__large">{item.aboutTitleOne}</h2>
                        <p className="font__mid weight__light about__text-one">{item.aboutTextOne}</p>
                    </motion.div>
                </section>

                <section className="about__bottom-section">
                    <motion.div
                        className="about__text-box"
                        variants={fadeRightVariants}
                        transition={{
                            duration: 1,
                            type: 'tween',
                            delay: 1.2
                        }}
                    >
                        <h2 className="page-headers font__large">{item.aboutTitleTwo}</h2>
                        <p className="font__mid about__text-two">{item.aboutTextTwo}</p>
                    </motion.div>
                    <motion.figure
                        className="about__image-two"
                        variants={fadeUpVariants}
                        transition={{
                            duration: 1,
                            type: 'tween',
                            delay: 1.8
                        }}
                    >
                        {item.aboutImageTwo && (
                            <GatsbyImage
                                image={item.aboutImageTwo.localFile.childImageSharp.gatsbyImageData}
                                alt="Lamppost"
                            />
                        )}
                    </motion.figure>
                </section>
            </motion.main>
        </Layout>
    );
};

export default AboutPage;
